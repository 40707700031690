import './App.css'
import {useEffect, useState} from "react"
import axios from "axios"


function App() {
    // maximal mögliche sätze zum auswählen
    const maxSelected = 10

    // backend url
    //const baseUrl = "http://127.0.0.1:8080/api/v1"
    const baseUrl = "/api/v1"

    // allgemeiner zustand
    const [isLoading, setIsLoading] = useState(undefined)
    const [hasError, setHasError] = useState(undefined)

    // Schritte:
    // - Ja/Nein Satzauswahl
    // - N top markieren/wählen
    // - Top rangieren
    // - Gespeichert
    const [inSelection, setInSelection] = useState(false)
    const [inMarking, setInMarking] = useState(false)
    const [inRanking, setInRanking] = useState(false)
    const [isSaved, setIsSaved] = useState(undefined)

    // Name zum Laden übereben?
    const [nameGiven, setNameGiven] = useState(false)

    // liste aller sätze aus der db
    const [sentences, setSentences] = useState([])

    // Schritt 1: Satzauswahl Ja/Nein
    // Aktueller Satz
    const [sentInd, setSentInd] = useState(-1)
    const [currentSentence, setCurrentSentence] = useState(null)
    // Wurde dieser Satz gewählt?
    const [selections, setSelections] = useState([])
    // Finale Liste von gewählten Sätzen
    const [selectedSentences, setSelectedSentences] = useState([])

    // Schritt 2: Top N markieren
    // Für jeden Satz Markierungszustand speichern
    const [markings, setMarkings] = useState([])
    // Finale Liste der gewählten Sätze
    const [markedSentences, setMarkedSentences] = useState([])
    // Kann noch ein Satz gewählt werden?
    const [canMark, setCanMark] = useState(false)

    // Annotator Name
    const [name, setName] = useState("");

    // initialer download der sätze
    useEffect(() => {
        setIsLoading(true)
        setHasError(undefined)

        let params = {}
        const searchParams = new URLSearchParams(window.location.search);

        let vUrl = ""
        if (searchParams.has("v")) {
            vUrl = searchParams.get("v")
        }

        if (searchParams.has("name")) {
            params["name"] = searchParams.get("name")
            setNameGiven(true)
            setName(params["name"])
        }
        else {
            setNameGiven(false)
        }

        axios.get(
            baseUrl + '/sentences/' + vUrl,
            {
                "params": params
            }
        )
            .then(response => {
                console.log(response)
                setSentences(response.data)
            })
            .catch(error => {
                console.error(error)
                setSentences([])
                setHasError(true)
            })
            .then(() => {
                setIsLoading(false)
            })
    }, [])

    // Sätze für Ja/Nein Auswahl vorbereiten
    useEffect(() => {
        // Directly start with marking if name is given
        if (nameGiven) {
            // All retreived sentences are selected in this case
            setSelections(Array(sentences.length).fill(true))
            setMarkings(Array(sentences.length).fill(false))

            setSelectedSentences(sentences)
            setMarkedSentences([])

            setCanMark(true)

            setInRanking(false)
            setInSelection(false)
            setInMarking(true)
        }
        else {
            // Start with first sentence
            setSentInd(0)

            // for each sentence, remember if selected
            setSelections(Array(sentences.length).fill(false))

            setInRanking(false)
            setInMarking(false)
            setInSelection(true)

            setSelectedSentences([])
            setMarkedSentences([])
        }
    }, [sentences, nameGiven])

    // update to the next sentence on index change
    useEffect(() => {
        if (sentences && sentences.length > 0 && sentInd >= 0) {
            setCurrentSentence(sentences[sentInd])
        } else {
            setCurrentSentence(null)
        }
    }, [sentences, sentInd])

    // Ja/Nein den aktuellen Satz
    function select(selection) {
        let newSelections = selections.slice()
        newSelections[sentInd] = selection
        setSelections(newSelections)
        if (sentInd < sentences.length - 1) {
        //if (sentInd < 10) {
            setSentInd(sentInd + 1);
        } else {
            // filter sentences if selection is true at this index
            const selected = sentences.filter((sentence, index) => newSelections[index])
            setMarkings(Array(selected.length).fill(false))

            setSelectedSentences(selected)
            setMarkedSentences([])

            setCanMark(true)

            setInSelection(false)
            setInMarking(true)
        }
    }

    // Einen Satz markieren
    function mark(index) {
        let newMarkedSentences = markings.slice()

        // count "true" values in markings in one line
        let markedCount = markings.filter(Boolean).length
        let isOk = markedCount < maxSelected

        const oldMarked = newMarkedSentences[index]
        // allow if <3 or old marked would be unmarked
        if (isOk || oldMarked) {
            newMarkedSentences[index] = !newMarkedSentences[index]
            setMarkings(newMarkedSentences)

            let marked = selectedSentences.filter((sentence, index) => newMarkedSentences[index])
            setMarkedSentences(marked)
        }

        markedCount = newMarkedSentences.filter(Boolean).length
        isOk = markedCount < maxSelected
        setCanMark(isOk)
    }

    // Rang eines Triples ändern
    function rank(index, direction) {
        let newRankings = markedSentences.slice();

        if (direction === +1 && index > 0) {
            [newRankings[index - 1], newRankings[index]] = [newRankings[index], newRankings[index - 1]];
        } else if (direction === -1 && index < newRankings.length - 1) {
            [newRankings[index + 1], newRankings[index]] = [newRankings[index], newRankings[index + 1]];
        }

        setMarkedSentences(newRankings);
    }

    // Finales Speichern der Daten
    function save() {
        if (!name) {
            return
        }

        setIsSaved(undefined)
        setIsLoading(true)
        setHasError(undefined)

        const payload = {
            name: name,
            sentences: markedSentences,
            selected: selectedSentences
        }

        axios.post(baseUrl + '/sentences/save', payload)
            .then(response => {
                console.log(response)
                if (response.data === true) {
                    setIsSaved(true)
                    setInRanking(false)
                } else {
                    setIsSaved(false)
                    setHasError(true)
                }
            })
            .catch(error => {
                console.error(error)
                setIsSaved(false)
                setHasError(true)
            })
            .then(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className="App container">

            {/* Fehleranzeige */}
            {hasError && (<div className={"row justify-content-md-center"}>
                <div className={"col-md-auto"}>
                    <div className={"alert alert-danger"}>
                        Ein Fehler ist aufgetreten, bitte versuchen Sie es nochmal oder kontaktieren sie uns.
                    </div>
                </div>
            </div>)}

            {/* Ladeanzeige */}
            {isLoading && (<div className={"row justify-content-md-center"}>
                <div className={"col-md-auto"}>
                    <div className={"alert alert-primary"}>
                        Bitte warten, Daten werden geladen...
                    </div>
                </div>
            </div>)}

            {!hasError && !isLoading && isSaved && (<div className={"row justify-content-md-center"}>
                <div className={"col-md-auto"}>
                    <div className={"alert alert-success"}>Ihre Auswahl wurde gespeichert, vielen Dank für die
                        Teilnahme!
                    </div>
                    <p>
                        Um Ihre Auswahl zu ändern laden sie bitte die Seite und verwenden sie beim Speichern den nochmal
                        gleichen Namen:
                        <div className={"alert alert-light"}><code>{name}</code></div>
                    </p>
                </div>
            </div>)}

            {/* Schritt 3: Rangierung */}
            {inRanking && (<div className={"row justify-content-md-center"}>
                <div className={"col-md-auto"}>
                    <h3>Ordnung der Satz-Tripel</h3>
                    <br/>
                    <p><strong>Ordnen Sie ihre gewählten Sätze</strong> für das Experiment von Wichtig (Rang 1) zu
                        Unwichtig.</p>
                    <div className={"row row-cols-auto"}>
                        {markedSentences.map((sentence, index) => (
                            <div className={"col-sm-12"} key={index}>
                                <div className={"card mb-4"}>
                                <div className={"card-body"}>
                                    <p>
                                        Rang:{' '}
                                        <span className="badge text-bg-primary">{index + 1}</span>
                                    </p>
                                    <p>
                                        <button
                                            className={"btn btn-success mx-2"}
                                            onClick={() => rank(index, +1)}
                                            disabled={index === 0}
                                        >
                                            Erhöhen
                                        </button>
                                        <button
                                            className={"btn btn-danger"}
                                            onClick={() => rank(index, -1)}
                                            disabled={index === markedSentences.length - 1}
                                        >
                                            Verringern
                                        </button>
                                    </p>
                                    <ul>
                                        {sentence["sentences"].map((inner_sentence, index) => (
                                            <li key={index}>Satz {index + 1}: <strong>{inner_sentence["sentence"]}</strong>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={"row justify-content-md-center mt-4"}>
                        <div className={"col-md-auto"}>
                            <p>Wenn sie mit dieser Rangierung einverstanden sind geben sie bitte Ihren Namen ein
                                und Speichern sie ihre Auswahl.</p>
                        </div>
                    </div>
                    <div className={"row justify-content-md-center"}>
                        <div className={"col-sm-6"}>
                            <input
                                className={"form-control"}
                                type={"text"}
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value)
                                }}
                                placeholder={"Name"}
                            />
                            {
                                isLoading ? <div className={"alert alert-primary mt-1"}>Wird gespeichert...</div> :
                                    <button
                                        className={"btn btn-success mt-2"}
                                        disabled={!name}
                                        onClick={() => {
                                            save()
                                        }}
                                    >
                                        Auswahl speichern
                                    </button>
                            }
                            {
                                isSaved === false ?
                                    <div className={"alert alert-danger mt-1"}>Konnte nicht gespeichert werden,
                                        bitte versuchen sie es erneut oder kontaktieren sie uns.</div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>)}

            {/* Schritt 2: Sätze markieren */}
            {!hasError && !isLoading && inMarking && (<div className={"row justify-content-md-center"}>
                <div className={"col-md-auto"}>
                    <h3>Auswahl der Satz-Tripel</h3>
                    <br/>
                    <p>Wählen Sie jetzt ihre <strong>bevorzugten {maxSelected} Sätze</strong> für das Experiment
                        durch <strong>Anklicken</strong> aus. Mit einem weiteren Klick können sie die Auswahl wieder entfernen.</p>
                    {!canMark && <div className={"alert alert-primary"}>Sie haben jetzt die maximal
                        möglichen {maxSelected} Satz-Tripel gewählt.</div>}
                    {nameGiven && selectedSentences.length === 0 && <div className={"alert alert-danger"}>
                        <strong>Fehler</strong> Unter dem Namen <em>{name}</em> wurden keine Sätze gefunden! Bitte rufen sie das Annotationstool ohne Namen erneut auf.
                    </div>}
                    <div className={"row row-cols-auto"}>
                        {selectedSentences.map((sentence, index) => (
                            <div className={"col-sm-4"}
                                 key={index}
                            >
                                <div className={"card mb-4 crea_box"}>
                                <div
                                    className={"card-body"}
                                    onClick={() => {
                                        mark(index)
                                    }}
                                >
                                    <p>
                                        Tripel: {' '}
                                        {
                                            markings[index] ?
                                                <span className="badge text-bg-success">ausgewählt</span> :
                                                <span className="badge text-bg-danger">nicht ausgewählt</span>
                                        }
                                    </p>
                                    <ul>
                                        {sentence["sentences"].map((inner_sentence, index) => (
                                            <li key={index}>Satz {index + 1}: <strong>{inner_sentence["sentence"]}</strong>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {!canMark && <div className={"alert alert-primary"}>Sie haben jetzt die maximal
                        möglichen {maxSelected} Satz-Tripel gewählt.</div>}
                    <div className={"row justify-content-md-center mt-4"}>
                        <div className={"col-md-auto"}>
                            <button
                                className={"btn btn-primary"}
                                onClick={() => {
                                    setInMarking(false)
                                    setInRanking(true)
                                }}
                            >
                                Mit dieser Auswahl fortfahren
                            </button>
                        </div>
                    </div>
                </div>
            </div>)}

            {/* Schritt 1: Sätze Ja/Nein AUswahl */}
            {!hasError && !isLoading && inSelection && currentSentence && (<div className={"row justify-content-md-center"}>
                <div className={"col-md-auto"}>
                    <h3>Auswahl der Satz-Tripel</h3>
                    <br/>
                    <p>
                        <strong>Satz-Tripel {sentInd + 1}</strong>/{sentences.length}
                    </p>
                    <ul>
                        {currentSentence["sentences"].map((sentence, index) => (
                            <li key={index}>Satz {sentence["index"] + 1}: <strong>{sentence["sentence"]}</strong>
                            </li>
                        ))}
                    </ul>
                    <p>Ist dieses Satz-Tripel für die Nutzung in dem Experiment geeignet?</p>
                    <div className={"row justify-content-md-center"}>
                        <div className={"col-md-auto"}>
                            <button
                                className={"btn btn-danger"}
                                style={{
                                    marginRight: "10px",
                                    width: "100px"
                                }}
                                onClick={() => {
                                    select(false);
                                }}
                            >
                                Nein
                            </button>
                            <button
                                className={"btn btn-success"}
                                style={{
                                    width: "100px"
                                }}
                                onClick={() => {
                                    select(true);
                                }}
                            >
                                Ja
                            </button>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default App;
